/** @jsx jsx */

import { jsx, Box, Heading, Grid, Button } from "theme-ui"
import { Link } from "../components/nav"
import { ContentBlock, HeaderSpacing, MapImageGlobal } from "../components/page"
import { Trans } from "react-i18next"
import { Fragment } from "react"

export default () => {
  return (
    <Fragment>
      <MapImageGlobal />
      <ContentBlock>
        <HeaderSpacing />
        <Box sx={{ width: ["100%", "100%", "50%"] }}>
          <Heading variant="headings.1" sx={{ mb: 3 }}>
            <Trans>
              Thank you for subscribing to the Progressive International
              briefing.
            </Trans>
          </Heading>
          <Heading as="h3" variant="headings.3" sx={{ mb: 5 }}>
            <Trans>
              Please consider joining our initiative or donating to help support
              our activities.
            </Trans>
          </Heading>
        </Box>
        <Grid
          gap={[0, 3]}
          columns={[0, 2]}
          sx={{ width: ["100%", "100%", "75%"] }}
        >
          <Link
            to={`/join${
              typeof window === `undefined` ? "" : window.location.search
            }`}
            sx={{ mb: [3, 0] }}
          >
            <Button variant="primary" sx={{ px: 5, width: "100%" }}>
              <Trans>Get Involved</Trans>
            </Button>
          </Link>
          <Link
            to={`/support${
              typeof window === `undefined` ? "" : window.location.search
            }`}
          >
            <Button variant="primary" sx={{ px: 5, width: "100%" }}>
              <Trans>Support us</Trans>
            </Button>
          </Link>
        </Grid>
      </ContentBlock>
    </Fragment>
  )
}
